import React from 'react'

const Contact = props => (
  <section id="contact">
    <div className="inner">
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>邮箱</h3>
            <a href="mailto:support@hongdingshi.cn">support@hongdingshi.cn</a>
          </div>
          {/* <div className="contact-method">
            <span className="icon"></span>
            <h3>电话</h3>
              028-88762091
          </div> */}
          <div className="contact-method">
            <span className="icon"></span>
            <h3>微信</h3>
            251902925
          </div>
        </section>
      </section>
    </div>
  </section>
)

export default Contact
